.lp_drawer_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lp_drawer_row_container > input {
  flex: 1;
  margin-left: 15px;
}

.lp_drawer_input_container, .lp_drawer_row_input, .lp_drawer_row_left_input {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}
.lp_drawer_row_input, .lp_drawer_row_left_input {
  min-width: 100px;
}
.lp_drawer_row_left_input {
  margin-right: 15px;
}

.lp_drawer_input {
  min-width: 0;
  min-height: 40px;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  padding-left: 15px;
  outline: none;
  box-sizing: unset;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.lp_drawer_password_eye {
  max-width: 26px;
  min-width: 26px;
  max-height: 26px;
  min-height: 26px;
  position: absolute;
  opacity: 0.4;
  top: 50%;
  right: 15px;
  cursor: pointer;
}

.lp_drawer_label {
  margin: 0 0 3px 10px;
}

@media only screen and (max-width: 900px) {
  .lp_drawer_row_container {
    max-width: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .lp_drawer_row_container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .lp_drawer_input {
    min-height: 30px;
  }

  .lp_drawer_input_container, .lp_drawer_row_input, .lp_drawer_row_left_input {
    margin-bottom: 7.5px;
  }

  .lp_drawer_label {
    font-size: 12px;
    margin-bottom: 0;
  }

  .lp_drawer_password_eye {
    top: 44%;
  }
}
