#wallColorControl,
#edgeSingleColorControl,
#otherColorControl {
  flex-direction: column;
  display: flex;
  width: 52px;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector,
div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector {
  display: flex;
  width: 60px;
  height: 60px;
  border: 1px solid #DFDFDF;
  border-radius: 6px 0 0 6px;
  background-color: #fff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: absolute;
  box-sizing: content-box;
}

div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.single-tile-color-selector {
  border-radius: 0 6px 6px 0;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles,
div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles {
  padding-bottom: 20px;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container,
div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container {
  position: absolute;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar {
  position: absolute;
  bottom: -15px;
  border-radius: 6px 0 0 0;
  margin-left: 2px;
}

div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar {
  margin-left: 0;
}

div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar {
  border-radius: 0 6px 0 0;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#edgeAllColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#wallSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown {
  padding-bottom: -10px;
  margin-top: -10px;
  margin-left: 0px;
}

div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown,
div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown {
  margin-left: 2px;
}

 div#wallColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles div.single-tile-color-div-text,
 div#edgeSingleColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles div.single-tile-color-div-text,
 div#otherColorControl.color-control-panel div.qs_main div.qs_content div.qs_container div.color-side-bar.shown div.side-bar-color-tiles div.single-tile-color-div-text {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 9px;
  text-transform: lowercase;
  text-align: center;
  position: relative;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  padding: 0px 3px;
  font-family: Calibri;
}

div#wallSingleColorControl.color-control-panel div.qs_main,
div#edgeAllColorControl.color-control-panel div.qs_main,
div#wallColorControl.color-control-panel div.qs_main,
div#edgeSingleColorControl.color-control-panel div.qs_main,
div#otherColorControl.color-control-panel div.qs_main {
  width: 100%;
}
div#wallSingleColorControl.color-control-panel div.qs_main div.single-tile-control-color-name-title,
div#edgeAllColorControl.color-control-panel div.qs_main div.single-tile-control-color-name-title,
div#wallColorControl.color-control-panel div.qs_main div.single-tile-control-color-name-title,
div#edgeSingleColorControl.color-control-panel div.qs_main div.single-tile-control-color-name-title,
div#otherColorControl.color-control-panel div.qs_main div.single-tile-control-color-name-title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 50px;
  height: 50px;
  top: 5px;
  left: 10%;
  text-align: center;
  text-transform: lowercase;
  pointer-events: none;
  z-index: 11;

  font-size: 9px;
}
