.tooltip {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  background-color: #404040;
  color: #fff;
  position: absolute;
  padding: 20px;
  max-width: 100px;
  border-radius: 6px;
  top: 0;
  right: -150px;
  box-sizing: unset;
}

@media only screen and (max-width: 1250px) {
   .tooltip {
    top: -70px;
    right: 0;
  }
}

@media only screen and (max-width: 320px) {
  .tooltip {
    font-size: 10px;
  }
}
