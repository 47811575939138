.checkoutPanelContainer {
  display: flex;
  position: absolute;
  height: calc(100% - 136px);
  width: 100%;
  margin-top: 36px;
  background-color: #E9E9E9;
  justify-content: center;
  align-items: center;
}

.checkoutPanelWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 500px;
  width: 335px;
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  gap: 16px;
}

.checkoutPanelFormWrapperBack {
  display: none;
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  background-color: #E9E9E9;
}

.checkoutPanelFormWrapper {
  display: flex;
  background-color: #FFFFFF;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  width: 335px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 6px;
}

.checkoutPanelFormHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.checkoutPanelFormTitle {
  color: #0054a6;
  margin: 0;
  margin-bottom: 16px;
}

.checkoutPanelCloseBtn {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgb(199 199 199);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgb(199 199 199);
  cursor: pointer;
}

.checkoutPanelForm {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.checkoutPanelFormInput {
  display: flex;
  min-width: 0;
  min-height: 40px;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  padding-left: 15px;
  outline: none;
  box-sizing: unset;
  margin-bottom: 15px;
}

.checkoutPanelFormSelect {
  flex: 1;
  display: flex;
  min-width: 80px;
  min-height: 40px;
  max-height: 40px;
  background-color: #fff;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  padding: 1px 0px 1px 10px;
  outline: none;
  box-sizing: unset;
  -webkit-appearance: none;
}

.checkoutPanelFormSelectContainer {
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.checkoutPanelFormSelectArrow {
  max-width: 26px;
  min-width: 26px;
  max-height: 26px;
  min-height: 26px;
  position: absolute;
  opacity: 0.4;
  top: 22%;
  right: 15px;
  pointer-events: none;
}

.checkoutPanelPhoneWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.checkoutPanelPhoneWrapper > select {
  width: 80px;
}

.checkoutPanelPhoneWrapper > input {
  flex: 1;
  margin-left: 16px;
}

.checkoutPanelFormSubmit {
  display: flex;
  justify-content: center;
  min-width: 0;
  min-height: 40px;
  background-color: #0054a6;
  color: #FFFFFF;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  box-sizing: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.checkoutPanelLabel {
  display: flex;
  border-radius: 6px;
  margin-bottom: 5px;
}

.checkoutPanelPricePrintWrapper,
.checkoutPanelLoadSaveWrapper {
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  height: 30%;
  justify-content: space-between;
}

.checkout-page-icon-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.checkout-page-icon-wrapper-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 30%;
  border-radius: 6px;
  background-color: #0054a6;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
}

.checkout-page-logics-wrapper {
  display: flex;
  width: 335px;
  max-width: 100%;
  height: 10%;
  margin-top: 10px;
  border-radius: 6px;
  align-items: center;
  font-size: 12px;
  user-select: none;
}

.checkout-page-logics-wrapper > a {
  text-decoration: none;
  cursor: pointer;
  color: #4a3f8f;
}

.checkout-page-logics-wrapper > a:visited {
  color: #4a3f8f;
}

.checkout-page-icon-wrapper-full img {
  margin-right: 20px;
}

@media screen and (max-width: 768px) and (orientation:portrait) {
  .checkoutPanelFormWrapperBack {
    justify-content: normal;
    overflow: scroll;
  }
}

@media screen and (max-width: 320px) and (orientation:portrait) {
  .checkoutPanelFormSelect {
    min-height: 30px;
  }

  .checkoutPanelFormSelectArrow {
    top: 18%;
  }

  .checkout-page-icon-wrapper {
    font-size: 12px;
  }

  .checkoutPanelWrapper {
    font-size: 12px;
  }

  .checkout-page-icon-wrapper-full {
    font-size: 12px;
  }
}
