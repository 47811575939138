@media screen and (min-width: 768px) {
  .edgeAllColorPicker, .edgeSingleColorPicker {
    bottom: calc(85px + 2.5%);
  }

  .edgeAllColorPicker {
    left: 75px;
  }

  .edgeSingleColorPicker {
    right: 25px;
  }

  .addObjectsModal {
    width: 400px;
    height: calc(100% - 86px);
    left: 50px;
    top: 36px;
    background-color: #dadada;
    border-right: 1px solid #c3c3c3;
  }

  #pop-up-tiles {
    background-color: #dadada;
  }

  .addObjectmodalDescriptionWrapper > img {
    display: none;
  }

  .modelControlsWrapper, .modelControlsWallColorPicker, .openAddObjectsBtnWrapper {
    bottom: calc(85px + 2.5%);
  }

  .modelControlsWallColorPicker {
    left: 75px;
  }

  .modelControlsWrapper {
    width: calc(100% - 50px);
    height: unset;
    border-radius: 6px;
    left: 50px;
    padding: 0 25px;
  }

  .modelControlsRemove {
    border-left: 1px solid #0054a6;
    border-radius: 6px;
    height: 50px;
  }

  .containerAngle {
    position: relative;
    top: 25px;
    background-color: #e9e9e9;
    width: 600px;
    flex: none;
    margin: 0;
    border-radius: 6px;
    padding-top: 30px;
    height: 80px;
    margin-top: -30px;
  }

  .checkoutPanelContainer {
    height: calc(100% - 86px);
  }

  div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper {
    position: fixed;
    box-sizing: content-box;
    width: 50px;
    height: calc(100% - 36px);
    top: 36px;
    left: 0;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: flex-start;
    border-right: 1px solid #f2f2f2;
  }

  div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper::after {
    display: flex;
    position: absolute;
    content: 'Crafted By Logic Solutions';
    width: max-content;
    height: 50px;
    bottom: -35px;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    align-items: center;
    color: #4a3f8f;
    display: none;
  }

  div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label.selected {
    background-color: #dadada;
    border-radius: 0;
  }

  div#navControl {
    height: 50px;
  }

  div#menu-navigation-arrows-wrapper.menu-navigation {
    width: calc(100% - 50px);
    margin-left: 50px;
    justify-content: space-between;
    border-left: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
  }

  div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label {
    flex: none;
  }

  #menu-navigation-left-arrow-wrapper {
    flex: none;
    padding-left: 25px;
    width: 160px;
    height: 100%;
    align-items: center;
  }

  #menu-navigation-middle {
    flex: none;
  }

  #menu-navigation-right-arrow-wrapper {
    flex: none;
    padding-right: 25px;
    width: 210px;
  }

.rightArrowWrapperRedBackground {
  background-color: #0054a6 !important;
}


  #menu-navigation-right-arrow .arrow.right {
    border: solid #000000;
    border-width: 0 4px 4px 0;
  }

  #menu-navigation-right-arrow-text-top {
    color: #ffffff;
    font-size: 16px;
  }

  #menu-navigation-right-arrow-bottom {
    color: #ffffff;
    font-size: 10px;
  }

  .logicS {
    display: flex !important;
    margin-top: auto;
    margin-bottom: 50px;
    align-self: center;
    writing-mode: vertical-lr;
    transform-origin: center;
    transform: rotate(180deg);
    color: #4a3f8f;
    text-decoration: none;
  }

  .logicS:visited, .logicS:active, .logicS:hover {
    color: #4a3f8f;
  }

  .logicS:before {
    color: black;
    content: "Crafted By";
  }

  .shapeSelect {
    height: calc(100% - 86px);
  }

  div#singleTileControl div.qs_main div.qs_content div#single-tile-selector-container.qs_container div#pop-up.pop-up.shown div#pop-up-wapper.pop-up-wapper {
    right: 0;
    top: 36px;
    height: calc(100% - 86px);
    position: absolute;
  }

  .single-tile-img-div {
    width: 10%;
    margin-left: 71px;
  }

  .single-tile-img-div img {
    margin-left: -71px;
  }

  .color-side-bar {
    margin-left: 1px !important;
  }

  .color-side-bar {
    border-radius: 6px !important;
  }

  div.single-tile-color-div-text {
    font-size: 12px;
  }

  .single-tile-color-selector {
    border-radius: 6px !important;
  }

  div#tileControl div.qs_main div.qs_content div#tile-selector-container.qs_container div#pop-up.pop-up.shown {
    height: calc(100% - 86px);
    top: 36px;
    left: 50px;
  }

  div#singleTileControl div.qs_main div.qs_content div#single-tile-selector-container.qs_container div#pop-up.pop-up.shown {
    /* right: 0; */
    width: 100%;
  }

  .tile-img-div {
    /* flex-direction: row-reverse; */
    width: 10%;
    margin-left: 71px;
  }

  .tile-img-div img {
    margin-left: -71px;
  }

  div#tileControl {
    left: 65px;
  }

  div#singleTileControl {
    right: 25px !important;
  }

  .pattern-pop-up-wrapper {
    width: 400px;
    height: calc(100% - 86px);
    top: 36px;
    left: 51px;
  }
}
