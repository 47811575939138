.wholePageloaderContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ffffff77;
  z-index: 200;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

@keyframes ldio {
  0% { transform: scale(1.1500000000000001) }
  100% { transform: scale(1) }
}

.loadingio-spinner {
  width: 75px;
  height: 75px;
  display: flex;
}

.ldio div {
  display: flex;
  position: relative;
  width: 40%;
  height: 40%;
  margin: 5%;
  background: #000000;
  animation: ldio 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.3s;
  box-sizing: border-box;
}

.ldio div:nth-child(2) {
  background: #e9e9e9;
  animation-delay: -0.2s;
  border: 2px solid;
}

.ldio div:nth-child(3) {
  background: #e9e9e9;
  animation-delay: 0s;
  border: 2px solid #0054a6;
}

.ldio div:nth-child(4) {
  background: #0054a6;
  animation-delay: -0.1s;
}

.ldio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
