/* pulsating border */
@-webkit-keyframes border-pulsate {
    0%   { outline-color: #0054a6cc; }
    50%  { outline-color: #39425333;  }
    100% { outline-color: #0054a6cc }
}
@keyframes border-pulsate {
    0%   { outline-color: #0054a6cc }
    50%  { outline-color: #39425333; }
    100% { outline-color: #0054a6cc }
}

@import "./isoControls.css";
@import "./navControls.css";
@import "./ShapeSelect.css";
@import "./EdgeStagePanel.css";
@import "./Switcher.css";
@import "./tileControls.css";
@import "./singleTileControls.css";
@import "./AddObjects.css";
@import "./landingPage.css";
@import "./arrowsControls.css";
@import "./wallAndOtherColorPicker.css";
@import "./Loader.css";
@import "./CheckoutPanel.css";
@import "./uiComponents/input.css";
@import "./uiComponents/tooltip.css";
@import "./uiComponents/select.css";
@import "./uiComponents/header.css";
@import "./uiComponents/userMenu.css";
@import "./desktop.css";
@import "./popups.css";

@font-face {
  font-family: 'Lato-Regular';
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

* {
  font-family: 'Lato-Regular', sans-serif;
  box-sizing: border-box;
}


html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #e9e9e9;
  position: relative;
  overscroll-behavior-y: contain;
}

body:hover, body:active, body:focus {
  -webkit-tap-highlight-color: transparent;
}

select {
  background-color: inherit;
}

canvas {
  display: flex;
  position: relative;
  outline: none;
  touch-action: none;
}

* {
  scrollbar-width: 3px;
  scrollbar-color: gray transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

* {
  scrollbar-width: 3px;
  scrollbar-color: gray transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

input[type=text] {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
