.addObjectsContainer {
  position: fixed;
  z-index: 7;
}

.modelControlsWrapper {
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  bottom: calc(100px + 4.5%);
}

.modelControlsRemove {
  display: flex;
  position: relative;
  background-color: #e9e9e9;
  width: 50px;
  height: 100%;
  padding: 8px;
  border: 1px solid #0054a6;
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}

.defaultControlsContainer {
  position: fixed;
}

.modelControlsWallColorPicker {
  display: flex;
  position: fixed;
  width: 62px;
  height: 62px;
  bottom: calc(100px + 4.5%);
}

.addObjectsColorPickerText {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  top: -45px;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  min-width: 62px;
}

.modelControlsAngle {
  display: flex;
  flex: 1;
  position: relative;
  margin: 0px 10px 0px 10px;
  height: 100%;
  border: 1px solid gray;
}

.modelControlsColorPicker {
  display: flex;
  flex-grow: 0;
  position: relative;
  width: 62px;
  height: 100%;
  top: -10px;
}

.openAddObjectsBtnWrapper {
  position: fixed;
  left: 50%;
  bottom: calc(100px + 4.5%);
}

.openObjectsModalBtn {
  background-color: #0054a6;
  color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 6px;
  margin-left: -50%;
  padding: 4px;
  cursor: pointer;
}

.addObjectsModalWrapper {
  position: fixed;
  display: flex;
  visibility: hidden;
  height: 100%;
  width: 100%;
}

.addObjectsModal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 8px;
}

.addObjectmodalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.addObjectmodalDescriptionWrapper {
  display: flex;
  height: 25px;
}

.addObjectmodalDescriptionText {
  user-select: none;
}

.closeAddObjectsBtn {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

.objectTypeSelectWrapper {
  display: flex;
  position: relative;
  background-color: #394253;
  border-radius: 6px;
  width: 100%;
  height: 63px;
  margin-bottom: 20px;
}

.objectTypeSelect {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  justify-content: space-around;
  user-select: none;
  cursor: pointer;
}

.objectTypeSelect:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.objectTypeSelect:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.objectTypeSelectImg {
  display: flex;
  height: 55%;
  align-self: center;
}

.objectTypeSelectText {
  display: flex;
  align-self: center;
  color: #fff;
  font-size: 12px;
}

.selectedObjectType {
  background-color: #0054a6;
}

.selectPoint {
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: inherit;
  bottom: -10px;
  left: calc(50% - 10px);
  transform: rotate(45deg);
}

#objectTypeSelectCar {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

#objectTypeSelectCabinet {
  border-left: 1px solid #E5171A;
  border-right: 1px solid #E5171A;
}

#objectTypeSelectOther {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.objectSelectWrapper {
  display: flex;
  position: relative;
  height: calc(100% - 116px);
}

.addObjectSelectionTab {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  overflow-y: overlay;
  width: 100%;
  max-height: 100%;
}

.objectImgWrapper{
  position: relative;
  width: calc(33.3333%);
  padding: 5px;
}

.addObject {
  display: flex;
  padding: 3px;
  width: 100%;
  height: 100%;
  background-color: grey;
  border-radius: 6px;
  /* height: 300px; */
  cursor: pointer;
}

.hiddenClass {
  visibility: hidden;
}

.containerAngle {
  display: flex;
  flex: 1;
  margin: 25px;
  flex-direction: column;
}

.box-minmaxAngle{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  color: gray;
  padding: 0px 25px;
}

.box-minmaxAngle > span:first-child {
  margin-left: 10px;
}

.range-sliderAngle {
  height: 0px;
  display: flex;
  position: relative;
  top: -2px;
  width: 100%;
}

.rs-rangeAngle {
  width: 100%;
  position: relative;
  -webkit-appearance: none;
}

.rs-rangeAngle:focus {
  outline: none;
}

.rs-rangeAngle::-webkit-slider-runnable-track {
  position: relative;
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: gray;
  border-radius: 0px;
  border: none;
}

.rs-rangeAngle::-moz-range-track {
  position: relative;
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: gray;
  border-radius: 0px;
  border: none;
}

.rs-rangeAngle::-webkit-slider-thumb {
  position: relative;
  z-index: 10;
  height: 50px;
  width: 50px;
  background-color: transparent;
  /* background-color: red; */
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -25px;
  border: none;
}

.rs-rangeAngle::-moz-range-thumb{
  position: relative;
  z-index: 10;
  height: 50px;
  width: 50px;
  background-color: transparent;
  /* background-color: red; */
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -25px;
  border: none;
}

.rs-rangeAngle::-moz-focus-outer {
  border: 0;
}

.rs-labelAngle {
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 50px;
  top: -23px;
  left: calc(50% - 24.5px);
  background-color: #e9e9e9;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  color: gray;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.sliderAngleNotch {
  display: flex;
  position: relative;
  height: 9px;
  width: 1px;
  background-color: gray;
}

.sliderAngleLabelWrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 19px);
  margin-top: 25px;
  left: 7px;
}

.sliderAngleLabel {
  display: flex;
  height: 10px;
  user-select: none;
}

/* #otherSliderAngleNotch0::before {
  display: flex;
  position: absolute;
  right: 1px;
  top: -1px;
	content: '';
  background-color: #e9e9e9;
  width: 23px;
  height: 1px;
}

#otherSliderAngleNotch4::before {
  display: flex;
  position: absolute;
  left: 1px;
  top: -1px;
	content: '';
  background-color: #e9e9e9;
  width: 23px;
  height: 1px;
} */
