div#navControl {
  /* position: sticky; */
  /* top: calc(100vh - 100px); */
  /* top: 0; */
  position: absolute;
  bottom: 0px;
  /* left: 0; */
  width:100%;
  /* border: 1px solid red; */
  z-index: 3;
  height: 100px;
  overflow: hidden;
  background-color: #e9e9e9;
}

div#menu-navigation-arrows-wrapper.menu-navigation {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
  background-color: white;
}

/* nav menu */
div#navControl div.qs_main {
  width: 100%;
  overflow: hidden;
  /* border-top: 1px solid #e5e5e5; */
}

div#navControl div.qs_main div.qs_content {
  width: 100%;
  overflow: hidden;
}

div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  /* border: 1px solid green; */
  width: 100%;
  overflow: hidden;
}


div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
}

div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label.selected {
  background-color: white;
  border-radius: 6px 6px 0 0;
}

div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label img {
  /* border: 1px solid blue; */
  width: 24px;
  height: 24px;
  cursor: pointer;
}

div#navControl div.qs_main div.qs_content div.qs_container div#menu-navigation-wrapper label input {
  /* border: 1px solid green; */
  display: none;
}

/* nav arrows */
#menu-navigation-left-arrow,
#menu-navigation-right-arrow {
  align-self: center;
  /* margin-left: 5px;
  margin-right: 5px; */
  margin: 4px;
}

.arrow {
  border: solid #0054a6;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#menu-navigation-arrows-wrapper {
  display: flex;
  flex: 2;
  flex-direction: row;
}

#menu-navigation-left-arrow-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: 8px;
  cursor: pointer;
}
#menu-navigation-right-arrow-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 8px;
  cursor: pointer;
}

#menu-navigation-right-arrow-text-wrapper {
  align-items: right;
  text-align: right;
}

#menu-navigation-left-arrow-text-top,
#menu-navigation-right-arrow-text-top {
  font-weight: 600;
  text-transform: uppercase;
}

#menu-navigation-left-arrow-bottom,
#menu-navigation-right-arrow-bottom {
  text-transform: uppercase;
  font-size: 8px;
}

#menu-navigation-middle {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  /* font: normal normal 600 19px Poppins; */
}

div#menu-navigation-wrapper > .logicS {
  display: none;
}
