div#arrowsControl {
  position: absolute;
  bottom: 193px;
  right: auto;
  left: auto;
  width:100%;
  z-index: 10;
  background-color: transparent;
  /* border: 1px solid red; */
}

div#arrowsControl div.qs_main {
  width: 100%;
}

div#arrowsControl div.qs_main div.arrowsControl {
  text-align: center;
  align-items: center;
  font-weight: 600;
  color: #090909;
  text-transform: uppercase;
  font-size: 10px;
}

div#arrowsControl div.qs_main div.qs_content div.qs_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* arrows */
div#arrowsControl div.qs_main div.qs_content div.qs_container div.left-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.right-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.up-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.down-arrow-wrapper {
  width: 51px;
  height: 51px;
  background: #CECECE 0% 0% no-repeat padding-box;
  border: 1px solid #FCFCFC;
  border-radius: 6px;
  margin: 0 2px;
}

div#arrowsControl div.qs_main div.qs_content div.qs_container div.left-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.right-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.up-arrow-wrapper,
div#arrowsControl div.qs_main div.qs_content div.qs_container div.down-arrow-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-pattern-arrow {
	/* more triangle */
	position: relative;
	height: 0px;
  width: 0px;
	border: 8px solid;
	border-color:
		#fff
		#fff
		transparent
		transparent;
    border-radius: 2px;
}

.move-pattern-arrow:before {
	content: '';
	position: absolute;
	top: 0px;
  right: 0px;
	display: block;
	height: 8px;
  width: 8px;
	background-color: #fff;
	transform:
		rotate(-45deg)
		translate(2px, 0px);
}

div#arrowsControl div.qs_main div.qs_content div.qs_container div.left-arrow.move-pattern-arrow {
  transform: rotate(-135deg);
}
div#arrowsControl div.qs_main div.qs_content div.qs_container div.right-arrow.move-pattern-arrow {
  transform: rotate(45deg);
}
div#arrowsControl div.qs_main div.qs_content div.qs_container div.up-arrow.move-pattern-arrow {
  transform: rotate(-45deg);
}
div#arrowsControl div.qs_main div.qs_content div.qs_container div.down-arrow.move-pattern-arrow {
  transform: rotate(135deg);
}
